import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about1_5 from "../../assets/about1_5.png";
import about1_6 from "../../assets/about1_6.png";
import about2_1 from "../../assets/about2_1.png";
import about2_2 from "../../assets/about2_2.png";
import about2_3 from "../../assets/about2_3.png";
import about2_3Mobile from "../../assets/mobile/about2_3.png";
import about2_4 from "../../assets/about2_4.png";
import about2_5 from "../../assets/about2_5.png";
import about3 from "../../assets/about3.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <h2 className="title">COMO PODEMOS TE AJUDAR?</h2>

          <p className="text">
            Nossas terapias são eficazes no tratamento, cura e alívio da dor.
            Escolha o que mais se identifica com a sua necessidade:
          </p>

          <div className="gallery">
            <div className="item">
              <figure>
                <img src={about1_1} alt="ACUPUNTURA" />
              </figure>

              <div className="bottom">
                <p className="title">ACUPUNTURA</p>

                <p className="text_card">
                  A acupuntura é uma terapia tradicional chinesa que traz
                  diversos benefícios. Ela ajuda a aliviar a dor, promover o
                  relaxamento muscular e reduzir o estresse, melhorando a saúde
                  mental. Além disso, pode fortalecer o sistema imunológico e
                  regular o equilíbrio energético do corpo, contribuindo para um
                  bem-estar geral e uma abordagem holística à saúde.
                </p>

                <CtaWpp
                  text={"Agendar uma sessão agora!"}
                  msg={
                    "Gostaria%20de%20agendar%20uma%20consulta%20de%20Acupuntura"
                  }
                />
              </div>
            </div>

            <div className="item">
              <figure>
                <img src={about1_2} alt="MOXABUSTÃO" />
              </figure>

              <div className="bottom">
                <p className="title">MOXABUSTÃO</p>

                <p className="text_card">
                  A moxabustão é uma técnica da medicina tradicional chinesa que
                  envolve a queima de ervas medicinais próximas a pontos
                  específicos do corpo. Seus benefícios incluem alívio da dor,
                  melhoria da circulação sanguínea, fortalecimento do sistema
                  imunológico e promoção do equilíbrio energético.
                </p>

                <CtaWpp
                  text={"Agendar uma sessão agora!"}
                  msg={
                    "Gostaria%20de%20agendar%20uma%20consulta%20de%20Moxabustão"
                  }
                />
              </div>
            </div>

            <div className="item">
              <figure>
                <img src={about1_3} alt="VENTOSATERAPIA" />
              </figure>

              <div className="bottom">
                <p className="title">VENTOSATERAPIA</p>

                <p className="text_card">
                  A ventosaterapia é um método terapêutico que envolve a
                  aplicação de copos aquecidos na pele, criando sucção. Isso
                  melhora a circulação sanguínea, alivia dores musculares,
                  promove relaxamento e pode auxiliar em problemas como
                  inflamações. É essencial que seja praticada por profissionais
                  qualificados para evitar complicações.
                </p>

                <CtaWpp
                  text={"Agendar uma sessão agora!"}
                  msg={
                    "Gostaria%20de%20agendar%20uma%20consulta%20de%20Ventosaterapia"
                  }
                />
              </div>
            </div>

            <div className="item">
              <figure>
                <img src={about1_4} alt="AURICULOTERAPIA" />
              </figure>

              <div className="bottom">
                <p className="title">AURICULOTERAPIA</p>

                <p className="text_card">
                  A auriculoterapia é uma técnica de medicina alternativa que
                  envolve a estimulação de pontos específicos na orelha. Seus
                  benefícios incluem alívio da dor, redução do estresse e
                  ansiedade, melhoria na circulação sanguínea e promoção do
                  equilíbrio energético do corpo. Essa prática pode complementar
                  tratamentos convencionais e contribuir para o bem-estar geral.
                </p>

                <CtaWpp
                  text={"Agendar uma sessão agora!"}
                  msg={
                    "Gostaria%20de%20agendar%20uma%20consulta%20de%20Auriculoterapia"
                  }
                />
              </div>
            </div>

            <div className="item">
              <figure>
                <img src={about1_5} alt="FISIOTERAPIA" />
              </figure>

              <div className="bottom">
                <p className="title">FISIOTERAPIA</p>

                <p className="text_card">
                  A fisioterapia emprega exercícios, técnicas manuais e
                  modalidades como eletroterapia para tratar lesões, reabilitar
                  o corpo, aliviar dores e melhorar a função física. Atua em
                  diversas áreas médicas, visando a recuperação e aprimoramento
                  da mobilidade e qualidade de vida.
                </p>

                <CtaWpp
                  text={"Agendar uma sessão agora!"}
                  msg={
                    "Gostaria%20de%20agendar%20uma%20consulta%20de%20Fisioterapia"
                  }
                />
              </div>
            </div>

            <div className="item">
              <figure>
                <img src={about1_6} alt="OZONIOTERAPIA" />
              </figure>

              <div className="bottom">
                <p className="title">OZONIOTERAPIA</p>

                <p className="text_card">
                  A ozonioterapia é um método terapêutico que utiliza ozônio
                  medicinal para tratar condições de saúde. Administra-se por
                  via intramuscular ou tópica, visando melhorar a oxigenação dos
                  tecidos, modular o sistema imunológico e reduzir inflamações.
                </p>

                <CtaWpp
                  text={"Agendar uma sessão agora!"}
                  msg={
                    "Gostaria%20de%20agendar%20uma%20consulta%20de%20Ozonioterapia"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <h2 className="title">
            DORES QUE TRATAMOS FREQUENTEMENTE EM NOSSO CONSULTÓRIO
          </h2>

          <div className="gallery">
            <div className="top">
              <figure className="left">
                <img src={about2_1} alt="DORES NO PESCOÇO" />
              </figure>

              <figure className="right">
                <img src={about2_2} alt="DORES MUSCULARES" />
              </figure>
            </div>

            <div className="middle">
              <figure>
                <img
                  src={
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                      navigator.userAgent
                    )
                      ? about2_3Mobile
                      : about2_3
                  }
                  alt="RELAXA, QUE A GENTE CUIDA DE VOCÊ!"
                />
              </figure>

              <CtaWpp />
            </div>

            <div className="bottom">
              <figure className="left">
                <img src={about2_4} alt="DORES NA LOMBAR" />
              </figure>

              <figure className="right">
                <img src={about2_5} alt="DORES NA COLUNA" />
              </figure>
            </div>
          </div>
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <div className="left">
            <h2 className="title">TENHA UMA VIDA SAUDÁVEL E SEM DOR</h2>

            <p className="text">
              Agende agora mesmo sua sessão com uma especialista em tratamentos
              de dores corporais!
            </p>

            <CtaWpp />
          </div>

          <figure className="right">
            <img src={about3} alt="Mariana Freire" />
          </figure>
        </div>
      </article>
    </section>
  );
};

export default About;
