import "./styles.css";

const Success = () => {
  return (
    <header id="success">
      <div className="container">
        <h2 className="title">
          Obrigado, em breve um de nossos representantes irá entrar em contato
        </h2>
      </div>
    </header>
  );
};

export default Success;
