import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import logo from "../../assets/logo_topo.png";
import header3_1 from "../../assets/header3_1.png";
import header3_2 from "../../assets/header3_2.png";
import header3_3 from "../../assets/header3_3.png";
import header3_4 from "../../assets/header3_4.png";
import header3_5 from "../../assets/header3_5.png";
import header3_6 from "../../assets/header3_6.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <div className="top">
            <figure className="logo">
              <img src={logo} alt="Logo" />
            </figure>

            <div className="cta">
              <CtaWpp text={"(11) 95046-2786"} />
            </div>
          </div>

          <div className="bottom">
            <h1 className="title">
              MAIS <span>QUALIDADE</span> DE VIDA COM <span>SAÚDE</span> E{" "}
              <span>BEM-ESTAR</span>
            </h1>

            <p className="text">
              Ajudamos a melhorar, tratar e aliviar a sua dor com sessões de
              acupuntura, moxabustão, auriculoterapia, ventosaterapia,
              ozonioterapia e fisioterapia.
            </p>

            <CtaWpp />
          </div>
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <p className="text">NOSSA MISSÃO É CUIDAR DE VOCÊ!</p>
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <h2 className="title">PARA UMA VIDA SEM DOR</h2>

          <div className="gallery">
            <div className="item">
              <figure>
                <img src={header3_1} alt="ACUPUNTURA" />
              </figure>

              <p className="text">ACUPUNTURA</p>
            </div>

            <div className="item">
              <figure>
                <img src={header3_2} alt="MOXABUSTÃO" />
              </figure>

              <p className="text">MOXABUSTÃO</p>
            </div>

            <div className="item">
              <figure>
                <img src={header3_3} alt="AURICULOTERAPIA" />
              </figure>

              <p className="text">AURICULOTERAPIA</p>
            </div>

            <div className="item">
              <figure>
                <img src={header3_4} alt="VENTOSATERAPIA" />
              </figure>

              <p className="text">VENTOSATERAPIA</p>
            </div>

            <div className="item">
              <figure>
                <img src={header3_5} alt="OZONIOTERAPIA" />
              </figure>

              <p className="text">OZONIOTERAPIA</p>
            </div>

            <div className="item">
              <figure>
                <img src={header3_6} alt="FISIOTERAPIA" />
              </figure>

              <p className="text">FISIOTERAPIA</p>
            </div>
          </div>

          <p className="text-bottom">
            Unimos tradição oriental, ciência ocidental e modernidade para criar
            tratamentos personalizados. Buscamos melhorar a saúde dos nosso
            pacientes de forma natural e ética, promovendo bem-estar e
            autonomia. "Cuidar" para nós, engloba alívio da dor, educação e
            orientação, garantindo saúde e qualidade de vida.
          </p>

          <CtaWpp />
        </article>
      </section>
    </header>
  );
};

export default Header;
